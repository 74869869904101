import React from "react"
import MasterLayout from "../components/MasterLayout";
import Seo from "../components/Seo";
import "./review.css";

export default function Review(props) {
  return (
    <MasterLayout>
      <Seo
        title={props.pageContext.frontmatter.title}
        description={props.pageContext.frontmatter.blurb || "no description"}
        pathname={props.uri}
        article={true}
      />
      <div className="main-content">
        <h1 className="article-title">{props.pageContext.frontmatter.title}</h1> 
        {props.children}
      </div>
    </MasterLayout>
  )
}