import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/review.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Having recently gone through Horizon: Forbidden West a second time while waiting for Fire Emblem Engage to drop, I came up with the idea to compare the two big open world RPGs that didn't win Game of the Year. Furthermore I never reviewed Forbidden West, and I did review Ragnarok. I'll preface this with the following disclaimer: both of these games are excellent, and released in any year besides one with Elden Ring and the other, they would have walked away with the GOTY easily. There may also be light spoilers ahead, though I don't think I'm going to give away any big reveals.`}</p>
    <h3>{`Graphics & Performance`}</h3>
    <p>{`When Zero Dawn was released it was the most beautiful looking game on the PS4. Period. End of story. It's looks still hold up today. Even though God of War released well over a year after Zero Dawn, I still think Zero Dawn is the better looking game. This time around I think Ragnarok wins. Not to say that Forbidden West is a bad looking game, but everything in Ragnarok just looks a bit crisper, the colors a bit more vibrant. I'd much rather spend more time trudging through the forests of Vanaheim than the forests on the "Pacific Northwest." Maybe that's just because I can do the latter on a daily basis. The deserts and sandstorms of Alfheim don't blur out screen the way they do in Las Vegas. I actually think the bigger sandstorms and swirling tornados look really bad in Forbidden West.`}</p>
    <p>{`I played both games on the PS5, both in Quality mode. Neither game ever experienced a perceptible frame rate drop and there were very few bugs. In fact, I can't recall a single bug in Ragnarok, as stated in my review. The only bug I experienced in Forbidden West, and it happened multiple times, was small animal drops despawning before I could get to them. When I played when Forbidden West first came out, I did have a strange bug where no matter what I did I couldn't fast travel, and it persisted through restarts of the PS5 and save game reloads. That didn't happen this time around.`}</p>
    <p><strong parentName="p">{`Score`}</strong>{`:`}</p>
    <p>{`Forbidden West: 0`}</p>
    <p>{`Ragnarok: 1`}</p>
    <h3>{`Story`}</h3>
    <p>{`You may recall that I said the story of Ragnarok felt disconnected at times. Almost like the devs were ticking off the realms that needed to get visited and coming up with shoddy reasons to do so. Why or how did Loki actually visit Jotenheim? Did someone pull him there? Did he do it subconsciously? Why are we going to see the muses? You learn almost nothing, and the characters choose to ignore the salient bits that are spoken. It's a non-event that lasts for 1-2 hours. Forbidden West does not have this problem. It's central narrative is tight. For each step in the main questline you know everyone's motivations, what you're doing, and why. Forbidden West has a different problem. It's problem is that due to the size of it's open world, and the number of inhabitants in it with their own problems, you don't `}<em parentName="p">{`need`}</em>{` to really engage in the main story at all, if you don't want to. Which seems odd given that it's a story about saving a world that has a few months left. Ragnarok also has this problem, I think most open world games do, but I don't think there's as much to do in Ragnarok nor is the traversable world as large. Furthermore outside of the core cast of the original game: Kratos, Atreus, Mimir, and the dwarves, I didn't really care about any of the new the side characters that were introduced. I cared more about the giant jellyfish that did nothing but look pretty during the night than Freya's brother, or Thor's daughter. Forbidden West does a much better job of handling it's new supporting characters, minor characters and quests, but again, it's at the cost of main story beats playing out over vaster swaths of time. I wanted to listen all of the dialog Fashav has to say, and it definitely made me want to listen to Hakkaro even more. Hell yeah I wanna make a machine prosthetic arm for Kotallo. No doubt about it, Forbidden West wins this category.`}</p>
    <p><strong parentName="p">{`Score`}</strong>{`:`}</p>
    <p>{`Forbidden West: 1`}</p>
    <p>{`Ragnarok: 1`}</p>
    <h3>{`Music`}</h3>
    <p>{`In preparation for this article I also listened to both of the soundtracks. Ragnarok's score, written by Bear McCreary, is excellent and full of epic bombastic tunes that you'd expect from a game about gods pummeling each other. Unfortunately I couldn't pick out a standout tune from amongst the lot. Forbidden West's score made greater use of motif through Aloy's theme, which is excellent. The music is also much more noticeable when you're just out and about walking or flying around. Maybe it's all the JRPGs I play, but I want that overworld music to be playing all the time and I don't need to be beaten over the head with an epic track, it can be something chill like These Stones Unturned.`}</p>
    <p><strong parentName="p">{`Score`}</strong>{`:`}</p>
    <p>{`Forbidden West: 2`}</p>
    <p>{`Ragnarok: 1`}</p>
    <h3>{`Combat`}</h3>
    <p>{`Here is where it gets tricky. Forbidden West did add at least two new weapon types and, while I love the spearcasters, the shredder gauntlets suck and I hate them. Forbidden West also tried it's best to revamp it's melee combat even going so far as to add a whole melee quest line which culminates in one of the most frustrating and RNG bound fights in the game. Melee combat also remains incredibly useless against machines, even the small Burrowers and Red Eyes. So even with these changes, it's mostly just shooting off parts with a bow until the machine dies. Luckily shooting off parts is still incredibly satisfying and there are a bunch of new machines to kill and sometimes use their own weapons against them. `}</p>
    <p>{`Ragnarok, also made very few changes to it's combat model. It's big innovations include a new weapon for Kratos (which I was `}<em parentName="p">{`not`}</em>{` a fan of) and letting you control Atreus several times throughout the story. Balance-wise Atreus feels broken in the segments you get to use him. Unlike with sad dad, I don't think I died once as Atreus, I felt like an avenging angel (valkyrie?) and nothing could get in my way. There are some new runes to use, and the skills you learn now can be augmented if used enough times. The augments don't feel game changing but by the end of the game you have so many materials and hacksilver you might as well max it all out. The game also doesn't do a great job of telling you how to level up your runes or Spartan Rage. It was probably about 18 hours in before I stumbled across that. There were plenty of new monsters to kill this time around too and a lot more verticality to the fights. Monsters would cling to walls and shoot at you, or you can drop attack them from above.`}</p>
    <p>{`Where there is a clear winner here is the set piece battles. It's not even close. If God of War has nailed anything over all of it's iterations, it's the boss battle. Every boss feels unique in it's own way, there almost always seems to be a unique mechanic or a new way of using your abilities you couldn't before in order to bring down some truly massive or awe-inspiring foes. Hell, the fight against Thor in the first hour of the game is better than all of Horizon's bosses put together. Horizon's "boss" battles are usually a group of machines, maybe in a confined space. Sometimes they're humans that can withstand 27 arrows sticking out of their noggin like Hellraiser's Pinhead. Sadly, for both games, the final boss encounters are pretty lackluster. Odin, the Allfather, felt incredibly easy to bring down and there was nothing Spectre Prime could do that a Thunderjaw couldn't do. `}</p>
    <p><strong parentName="p">{`Score`}</strong>{`:`}</p>
    <p>{`Forbidden West: 2`}</p>
    <p>{`Ragnarok: 2`}</p>
    <h3>{`Conclusion`}</h3>
    <p>{`There you have it, a tie! If I really had to pick a winner though, the game with the better story handling and music will win it for me every time.`}</p>
    <p><strong parentName="p">{`Hot Takes GOTY 2022`}</strong></p>
    <p>{`Neon White :trollface:`}</p>
    <p><strong parentName="p">{`2nd Place`}</strong></p>
    <p>{`Horizon: Forbidden West`}</p>
    <p><strong parentName="p">{`3rd Place`}</strong></p>
    <p>{`God of War: Ragnarok`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      